import { createRouter, createWebHistory } from 'vue-router'

/* 主页 */
const Home = () => import('../pages/HomePage.vue')
/* 工具页 */
const MapList = () => import('../pages/MapList.vue')
const MapQuery = () => import('../pages/MapQuery.vue')
const MapResultExtract = () => import('../pages/MapResultExtract.vue')
/* 关于页 */
const About = () => import('../pages/AboutPage.vue')
/* 登录注册页 */
const Login = () => import('../pages/LoginPage.vue')
const Register = () => import('../pages/RegisterPage.vue')
/* FAQ页 */
const FAQ = () => import('../pages/FAQPage.vue')
/* 管理页 */
const Admin = () => import('../pages/AdminPage.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tools/maplist',
    name: 'MapList',
    component: MapList
  },
  {
    path: '/tools/mapquery',
    name: 'MapQuery',
    component: MapQuery
  },
  {
    path: '/tools/mapresultextract',
    name: 'MapResultExtract',
    component: MapResultExtract
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/icebear-admin',
    name: 'Admin',
    component: Admin
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router