export const zhCN = {
    index: {
        'home': '主页',
        'tools': '工具',
        'mapList': '地图列表',
        'mapQuery': '地图查询',
        'mapResultExtract': '提取结果',
        'mapFastDl': '地图高速下载',
        'meme': 'meme',
        'about': '关于',
        'faq': '常见问题',
        'login': '登录',
        'register': '注册',
        'logout': '登出',
        'switchlightmode': '切换到亮色模式',
        'switchdarkmode': '切换到暗色模式',
        'copyright': '版权所有'
    },
    home: {
        'welcome': '欢迎来到熊熊地图管理组网站',
        'subtitle': '本网站与北极熊无任何关系, 仅为北极熊地图管理组开发和面向大众使用!',
        'features': {
            'mapList': {
                'title': '地图列表',
                'description': '使用网站查看北极熊地图列表'
            },
            'mapQuery': {
                'title': '地图查询',
                'description': '使用网站查询北极熊地图下载方式'
            },
            'resultExtract': {
                'title': '结果查询',
                'description': '使用网站查询给出的查询码的结果'
            },
            'mapFastDl': {
                'title': '地图高速下载',
                'description': '暂未开放'
            },
            'meme': {
                'title': 'Meme 图',
                'description': '暂未开放'
            }
        },
        'about': {
            'title': '关于',
            'description': '点击按钮以了解更多关于本网站信息',
            'learnmore': '了解更多'
        },
        'sponsors': {
            'title': '感谢以下赞助者!',
            'amount': '金额: '
        }
    },
    about: {
        'title': '关于本网站',
        'descriptionTitle': '网站说明',
        'description': '本网站旨在为北极熊及其粉丝提供地图查询, 地图查询结果提取等服务, 未经授权严禁抄袭本网站!',
        'thanksTitle': '感谢列表',
        thanks: {
            'heimnad': '🌟 HeimNad - 网站开发者',
            'icebear': '🌟 北极熊Icebear - B站MC汉化区创作者',
            'beiyao': '🌟 北遥 - 提供灵感支持',
            'translator': '🌟 Roser/思遥方/青山原不老 - 英文/火星文/日文 翻译'
        },
        'friendsLinkTitle': '友链',
        friendsLinks: {
            'heimnad': {
                'title': 'HeimNad 的主页',
                'description': '开发者的个人页面'
            },
            'hfpro': {
                'title': '心火计划',
                'description': '一个关于 Minecraft 的计划'
            }
        },
        'linkVisit': '点击访问',
        'contactTitle': '联系方式',
        'contactDescription': '如有任何问题, 请通过以下方式联系我:',
        'email': '📧 邮箱: ',
        'qq': '🐧 QQ: '
    },
    faq: {
        'title': '常见问题',
        'contenttable': '目录',
        qqgroup: {
            'title': '北极酒馆',
            1: {
                'title': '进群必知',
                1: {
                    'question': '必知公告 - 1',
                    'answer': '0. 本群极度建议您掌握了MC基础知识(包含懂得如何分辨版本, 如何分辨mod端以及加载器的区别, 懂得如何导入地图等)再进行游玩, 本群不是面向纯萌新的交流群, 若您是纯萌新请善于使用互联网进行学习后再来玩地图(读前提示: 北极熊是个暴脾气, 会在群里不定时出现. 请尽量避免提出智障问题等, 以免被踢)\n1. 严禁在群内发布黄色、涉政以及暴力血腥等相关内容, 一经发现, 将直接踢出本群.\n2. 请勿搜索非北极熊的地图, 避免产生不必要的麻烦.\n3. 管理都很忙, 请在{\'@\'}管理之前确保您已经看过了地图文档/群公告/协议搜索结果等, 并准确无误且简短地描述自己的问题, 截图等.若缺少以上其一还{\'@\'}管理的话后果自负(包括但不限于禁言/BOT拉黑/踢群等).\n4. 禁止刷屏、辱骂等不良行为.若有此类情况, 将视情节严重程度予以禁言处理, 情节特别严重者直接踢出群聊.\n5. 请不要过分的{\'@\'}包括北极熊在内的管理组, 多次{\'@\'}会导致禁言.\n6. 禁止传播地图, 违者将永久踢出群聊.\n7. 在发布视频/直播时请标注汉化作者并{\'@\'}北极熊.\n8. 在群内想寻找视频里的地图时, 请直接发视频链接, 而不是单一的截图.\n9. 请不要发令人不适的(例如擦边球)图片/表情包/文字/视频等.违者视情况禁言甚至踢出处理.\n10. 如果您想邀请您的朋友, 请按照原先审核方式进入.'
                },
                2: {
                    'question': '必知公告 - 2',
                    'answer': '机器人使用/help查看帮助, 查之前先去文档\nhttps://docs.qq.com/sheet/DVHBWRmFqYVFucG1p?tab=BB08J2\n找到你想要的地图(如果这个看完都还要在群里瞎问我真的没办法了......)'
                },
                3: {
                    'question': '必知公告 - 3',
                    'answer': '在您发言前, 您至少应该看完以下内容\n» 您应当在充分了解 Minecraft Java/Bedrock edition 游玩所需具备的基础知识(包括但不限于如何以正确的版本与方式导入各类资源, 如何安装mod加载器, 如何安装地图所需求的特定资源等)后使用本群提供给您的任何服务；对以上内容有所不了解的, 我们诚邀您善用各大搜索引擎自行学习或解决问题, 总之不要把这种问题提到群里\n» 您应当了解一定的义务教育基础英语\n    search v. 搜索\n    bot n. 机器人\n    feedback(fb) v. 反馈\n    policy n. 政策\n    agree v. 同意\n    disagree v. 不同意\n    show v. 展示\n    help v. 帮助\n    remove v. 移除\n    add v. 添加\n» 我们不提倡您使用任何启动器, 任何游戏本体或任何本群提供的资源；网络上的任何资源都有可能有病毒, 更何况是来自国外的资源, 若您怕怕, 请您滚滚\n» 您应当全额阅读群公告并遵守其中内容, 提出的问题若是群公告内已完全写明的内容, 则您可能会被封禁'
                },
                4: {
                    'question': '必知公告 - 4',
                    'answer': '很遗憾由于我们提供的服务并没有收费, 导致我们并没有资金去购买群人数升级. 这导致我们并无法承载很多的群员, 也就导致我们可能会清除不活跃群成员…… 真的非常抱歉, 被踢之后你可以再加一次的.'
                }
            },
            2: {
                'title': '注意事项',
                1: {
                    'question': '关于刚入群被加入黑名单',
                    'answer': '请注意, 若您的 QQ 等级 < 四级(一个月亮以下), 则很有可能会被管理组误判为小号并加入黑名单.\n这时候需填写黑名单表单(会在您搜索时出现)并耐心等待通过审核后方可继续使用.'
                },
            }
        },
        mc: {
            'title': '我的世界',
            1: {
                'title': '存档相关',
                1: {
                    'question': '如何安装/导入地图?',
                    'answer': 'https://www.bilibili.com/video/BV1hM4y1V7rY'
                },
                2: {
                    'question': '如何安装/导入数据包?',
                    'answer': 'https://www.bilibili.com/video/BV18T41177ZX'
                },
                3: {
                    'question': '如何安装/导入资源包?',
                    'answer': 'https://www.bilibili.com/video/BV1ot4y1M76p'
                }
            },
            2: {
                'title': '服务器相关',
                1: {
                    'question': '如何搭建一个服务器?',
                    'answer': 'https://www.bilibili.com/video/BV1rq4y117uA'
                }
            }
        },
        website: {
            'title': '熊熊网站',
            1: {
                'question': '这个网站是干什么的?',
                'answer': '主要用于提供关于北极熊翻译的地图的相关服务, 包括但不限于查询, 提取, 高速下载等.'
            },
            2: {
                'question': '这个网站会收集我的信息吗?',
                'answer': '我们使用 微软 Microsoft Clarity, 谷歌 Google Analysis/Tag Manager, Sentry Issue Tracker 来收集关于网站的使用记录以及问题记录. 如果您不接受以上公司的服务政策, 请您立刻退出网站并联系管理员删除您的信息.'
            },
            3: {
                'question': '这个网站收费吗?',
                'answer': '我们目前提供免费服务, 包括但不限于查询, 提取. 高速下载可能需要您观看广告后才可以获取高速连接, 我们目前还没有开放这个功能.'
            }
        },
        other: {
            'title': '其他',
            1: {
                'question': '暂无',
                'answer': '空'
            },
        }
    },
    login: {
        'title': '欢迎登录',
        'emailLabel': 'QQ号',
        'passwordLabel': '密码',
        'rememberMe': '记住我',
        'forgotPassword': '忘记密码',
        'signIn': '登录',
        'or': '或者',
        'successMessage': '登录成功!',
        'errorMessage': '登录失败, 错误信息: {message}'
    },
    register: {
        'title': '欢迎注册',
        'emailLabel': 'QQ号',
        'passwordLabel': '密码',
        'confirmPasswordLabel': '确认密码',
        'inviteCodeLabel': '邀请码',
        'signUp': '注册',
        'or': '或者',
        'tooltip': '密码必须包含至少8个字符, 并且包括大写字母, 小写字母和数字.',
        'passwordError': '密码不符合要求!',
        'confirmPasswordError': '两次输入的密码不一致!',
        'successMessage': '注册成功!即将跳转登录页.',
        'errorMessage': '注册失败, 错误信息: {message}'
    },
    tools: {
        mapquery: {
            'title': '地图查询',
            'username': '用户名',
            'queryarg': '查询参数',
            'querytime': '查询时间',
            'querybutton': '查询',
            'queryloading': '查询中',
            'totalresults': '查到总数',
            'resultcount': '条记录',
            'attention': '⚠️注意!',
            'attentiondetail': '你还没有登录, 请先点击右上角登录按钮进行登录后再进行操作!',
            'inputerror': '请输入查询内容!',
            'mapname': '地图名',
            'mapdetails': '详细信息',
            'mapratio': '匹配度'
        },
        mapresultextract: {
            'title': '地图已查询列表提取',
            'attention': '⚠️注意!',
            'attentiondetail': {
                '1': '如果没有找到你需要的结果, 或者你并没有在你所在的北极酒馆的群文件中找到详细信息为"群文件"的地图, 请前往你所在的北极酒馆使用/fb 问题(问题不要带空格)向管理组发送反馈, 如有长时间未回复问题再前往您所在的北极酒馆{\'@\'}如下管理:',
                '2': '北京时间白天: 2383615282, 1956013526, 3560481179',
                '3': '北京时间夜间: 5278626'
            },
            'inputerror': '请输入提取码!',
            'resultexpiration': '提取码已过期!'
        }
    }
}